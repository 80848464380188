import React from "react";
import { Footer, Navbar, Product } from "../components";

const Products = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Product />
      <Footer />
    </>
  );
};

export default Products;
