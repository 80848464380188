import React from "react";
import { Footer, Navbar } from "../components";

const AboutPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const products = [
    {
      title: "Organic Rice and Millets",
      description:
        "Explore our collection of premium organic rice and millets, perfect for a healthy diet.",
      image:
        "https://images.pexels.com/photos/7420979/pexels-photo-7420979.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      title: "Organic Pulses",
      description: "Discover our range of organic pulses, rich in protein.",
      image:
        "https://images.pexels.com/photos/1393382/pexels-photo-1393382.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      title: "Organic Honey",
      description:
        "Indulge in the natural sweetness of our organic honey, sourced from local beekeepers.",
      image:
        "https://images.pexels.com/photos/33260/honey-sweet-syrup-organic.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      title: "Organic Ghee",
      description:
        "Experience the rich flavor of our organic ghee, made from pure cow's milk.",
      image:
        "https://images.pexels.com/photos/20689447/pexels-photo-20689447/free-photo-of-bowls-with-liquid-and-food-ingredients.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
  ];

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 my-8">
        <h1 className="text-center text-4xl font-bold mb-4 text-gray-900">
          Prop The Crop
        </h1>
        <p className="text-center text-lg italic mb-8 text-lime-600">
          Touch of Nature
        </p>
        <hr className="mb-12 border-lime-500" />

        {/* About Section */}
        <section className="text-gray-700 mb-20 h-">
          <p className="text-center mx-auto max-w-2xl leading-relaxed mb-12">
            Prop the Crop is dedicated to providing purely organic products that
            bring the essence of nature directly to your table. We offer a wide
            range of organic goods such as millets, honey, rice, pulses, oils,
            spices, coffee, and more. We are committed to quality and ensuring
            all our products are cultivated naturally, without harmful chemicals
            or pesticides.
          </p>

          <div className="flex flex-col lg:flex-row items-stretch justify-between space-y-10 lg:space-y-0 lg:space-x-10">
            {/* Mission */}
            <div className="flex-1 bg-white p-6 rounded-lg shadow-md flex flex-col h-80 text-justify">
              <div className="flex items-center mb-4">
                <div className="h-10 w-10 bg-lime-500 rounded-full flex items-center justify-center text-white">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>
                </div>
                <h2 className="ml-4 text-xl font-semibold text-gray-900">
                  Mission
                </h2>
              </div>
              <p className="text-gray-600 flex-grow">
                Our mission is to promote a healthier lifestyle by making
                high-quality organic products accessible to everyone. We support
                sustainable farming practices that respect the environment and
                aim to offer nutritious, chemical-free products.
              </p>
            </div>

            {/* Vision */}
            <div className="flex-1 bg-white p-6 rounded-lg shadow-md flex flex-col h-80 text-justify">
              <div className="flex items-center mb-4">
                <div className="h-10 w-10 bg-lime-500 rounded-full flex items-center justify-center text-white">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <h2 className="ml-4 text-xl font-semibold text-gray-900">
                  Vision
                </h2>
              </div>
              <p className="text-gray-600 flex-grow">
                We envision a world where organic farming is the norm. Our goal
                is to become a leading brand in the organic market, known for
                our commitment to purity, sustainability, and customer
                satisfaction.
              </p>
            </div>

            {/* Core Values */}
            <div className="flex-1 bg-white p-6 rounded-lg shadow-md flex flex-col h-auto text-justify">
              <div className="flex items-center mb-4">
                <div className="h-10 w-10 bg-lime-500 rounded-full flex items-center justify-center text-white">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                </div>
                <h2 className="ml-4 text-xl font-semibold text-gray-900">
                  Core Values
                </h2>
              </div>
              <p className="text-gray-600 flex-grow">
                <span class="material-symbols-outlined text-lime-400">
                  check_circle
                </span>{" "}
                Quality: Ensuring organic purity and taste. <br />
                <span class="material-symbols-outlined text-lime-400">
                  check_circle
                </span>{" "}
                Sustainability: Promoting eco-friendly farming. <br />
                <span class="material-symbols-outlined text-lime-400">
                  check_circle
                </span>
                Transparency: Clear communication about our processes. <br />
                <span class="material-symbols-outlined text-lime-400">
                  check_circle
                </span>
                Health: Offering products that encourage a healthy lifestyle.{" "}
                <br />
                <span class="material-symbols-outlined text-lime-400">
                  check_circle
                </span>{" "}
                Community: Building strong relationships with customers and
                partners.
              </p>
            </div>
          </div>
        </section>

        {/* Product Section */}
        <section className="text-gray-700">
          <div className="mb-12">
            <h2 className="text-3xl font-bold text-center mb-2 text-gray-900">
              Our Products
            </h2>
            <p className="text-center text-gray-600">
              We offer a variety of products to meet all your needs. From
              organic foods to fashion and electronics, discover our range of
              high-quality items.
            </p>
            <div className="h-1 w-16 bg-lime-500 mx-auto my-4 rounded"></div>
          </div>

          <div className="flex flex-wrap -m-4">
            {/* Product Cards */}
            {products.map((product, index) => (
              <div key={index} className="p-4 lg:w-1/4 md:w-1/2">
                <div className="bg-gray-100 p-6 rounded-lg shadow-md h-96">
                  <img
                    className="h-40 rounded w-full object-cover mb-6"
                    src={product.image}
                    alt={product.title}
                  />
                  <h3 className="tracking-widest text-lime-500 text-xs font-medium">
                    {product.title}
                  </h3>
                  <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                    {product.description}
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
