import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { Footer, Navbar } from "../../components"; // Ensure these components exist
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor to include the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const token = auth?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Initial state for the reducer
const initialState = {
  orders: [],
  filteredOrders: [],
  filter: 1, // Default filter to "Ordered"
  loading: true,
};

// Reducer function to manage state
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDERS":
      return {
        ...state,
        orders: action.payload,
        filteredOrders: action.payload.filter(
          (order) => order.status === state.filter
        ),
      };
    case "SET_FILTER":
      return {
        ...state,
        filter: action.payload,
        filteredOrders: state.orders.filter(
          (order) => order.status === action.payload
        ),
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

const UserOrders = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate(); // Initialize navigate

  // Check user authentication on component mount
  useEffect(() => {
    const checkAuth = async () => {
      const token = JSON.parse(localStorage.getItem("auth"))?.token;
      if (token) {
        try {
          await axios.post(`${BASE_URL}/verifyUser`, { token });
        } catch (error) {
          alert("You must be logged in to access this page");
          navigate("/login");
          localStorage.removeItem("auth");
        }
      } else {
        alert("You must be logged in to access this page");
        navigate("/login");
      }
    };

    checkAuth();
  }, [navigate]);

  // Fetch user data on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        // Retrieve auth data from localStorage
        const auth = JSON.parse(localStorage.getItem("auth"));
        const userId = auth?.user_id;

        if (!userId) {
          throw new Error("User ID not found in auth data.");
        }

        // Make GET request to /userDetails with user ID as query parameter
        const response = await axiosInstance.get(`${BASE_URL}/userDetails`, {
          params: { id: userId },
        });

        const { orderProducts } = response.data;

        // Update orders state
        dispatch({ type: "SET_ORDERS", payload: orderProducts });
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user data");
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };

    fetchData();
  }, []);

  // Handle filter changes for orders
  const handleFilterChange = (filterType) => {
    dispatch({ type: "SET_FILTER", payload: filterType });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Toaster />
      <Navbar />
      <div className="container mx-auto px-4 py-6">
        <div className="bg-white shadow-md rounded-lg p-6">
          {state.loading ? (
            <div className="text-center">
              <svg
                className="animate-spin h-8 w-8 text-gray-600 mx-auto mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
              <p className="text-lg text-gray-500">Loading...</p>
            </div>
          ) : (
            <OrdersView
              filter={state.filter}
              filteredOrders={state.filteredOrders}
              handleFilterChange={handleFilterChange}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

// OrdersView Component
const OrdersView = ({ filter, filteredOrders, handleFilterChange }) => (
  <div className="space-y-6">
    <div className="flex flex-col md:flex-row justify-between items-center">
      <h2 className="text-2xl font-semibold text-gray-800">Your Orders</h2>
      <div className="flex space-x-2 mt-4 md:mt-0">
        <FilterButton
          label="Ordered"
          active={filter === 1}
          onClick={() => handleFilterChange(1)}
        />
        <FilterButton
          label="Delivered"
          active={filter === 0}
          onClick={() => handleFilterChange(0)}
        />
      </div>
    </div>
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead>
          <tr>
            <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
              Product Name
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
              Quantity
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
              Price per Unit
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.length > 0 ? (
            filteredOrders.map((order) => (
              <tr key={order.pid} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {order.productName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {order.qty}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  ₹{order.price}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {order.status === 0 ? (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold bg-green-100 text-green-800">
                      <span className="material-symbols-outlined text-sm mr-1">
                        check_circle
                      </span>
                      Delivered
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold bg-gray-100 text-gray-800">
                      <span className="material-symbols-outlined text-sm mr-1">
                        archive
                      </span>
                      Ordered
                    </span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="4"
                className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500"
              >
                No orders found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

// FilterButton Component
const FilterButton = ({ label, active, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center space-x-1 px-4 py-2 rounded-lg transition ${
      active
        ? "bg-lime-500 text-white shadow-lg"
        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
    }`}
  >
    <span className="material-symbols-outlined text-sm">
      {active ? "check_circle" : "circle"}
    </span>
    <span>{label}</span>
  </button>
);

export default UserOrders;
