import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <div className="container mx-auto p-6">
        <h1 className="text-4xl font-bold mb-4 text-lime-600">
          Terms and Conditions
        </h1>
        <p className="mb-6 text-gray-700">
          Welcome to Prop The Crop! By using our website and purchasing our
          products, you agree to comply with the following terms and conditions.
          Please read them carefully before placing your order.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Use of the Website
        </h2>
        <p className="mb-6 text-gray-700">
          By accessing our website, you warrant that you are at least 18 years
          old or have the permission of a legal guardian to use this site. You
          agree to use the site for lawful purposes and in a manner that does
          not infringe the rights of others.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">Products</h2>
        <p className="mb-6 text-gray-700">
          Prop The Crop offers a variety of organic products. While we strive to
          ensure product availability, we cannot guarantee that all products
          will be in stock at all times. Product descriptions, prices, and
          availability are subject to change without notice.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Orders and Payments
        </h2>
        <p className="mb-6 text-gray-700">
          When you place an order with us, you agree to provide accurate payment
          and shipping details. We reserve the right to refuse or cancel any
          order if we suspect fraud or unauthorized activity. All payments must
          be made in full before the products are shipped.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Shipping and Delivery
        </h2>
        <p className="mb-6 text-gray-700">
          We make every effort to ship orders promptly, but delivery times may
          vary depending on your location and availability of the products. Any
          shipping delays do not constitute grounds for cancellation or
          compensation.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Returns and Refunds
        </h2>
        <p className="mb-6 text-gray-700">
          Our return policy allows you to return items within 30 days of
          receipt, subject to the terms outlined in our{" "}
          <Link to="/return-policy" className="text-lime-600 font-semibold">
            Return Policy
          </Link>
          . Refunds will be processed after we receive and inspect the returned
          items.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Limitation of Liability
        </h2>
        <p className="mb-6 text-gray-700">
          Prop The Crop will not be liable for any direct, indirect, incidental,
          or consequential damages arising from the use of our products or
          website, including but not limited to damages for loss of data,
          profit, or business interruptions.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Changes to the Terms
        </h2>
        <p className="mb-6 text-gray-700">
          We reserve the right to modify these terms at any time. Any changes
          will be effective immediately upon posting on our website. It is your
          responsibility to review the terms periodically.
        </p>

        <h2 className="text-2xl font-semibold mb-2 text-lime-500">
          Contact Us
        </h2>
        <p className="text-gray-700">
          If you have any questions about these Terms and Conditions, please
          contact us at{" "}
          <span className="text-lime-600 font-semibold">
            support@propthecrop.com
          </span>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
