import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Footer, Navbar } from "../components";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../src/components/assets/logo.png";

const PaymentGateway = () => {
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const cartItems = useSelector((state) => state.cart) || [];
  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.price * item.qty,
    0
  );
  const totalItems = cartItems.reduce((acc, item) => acc + item.qty, 0);

  let Discount = 0.0;

  if (subtotal > 2500) {
    Discount = 300;
  } else if (subtotal > 1500) {
    Discount = 200;
  } else if (subtotal > 1000) {
    Discount = 150;
  } else if (subtotal > 500) {
    Discount = 50;
  } else {
    Discount = 0;
  }
  const dispatch = useDispatch();

  // Loading details from local storage
  const userDetails = JSON.parse(localStorage.getItem("billingAddress"));
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("auth"))?.token;

  // Check if user is logged in before proceeding to payment
  const handleOnCheckout = async () => {
    if (token) {
      try {
        await axios.post(`${BASE_URL}/verifyUser`, {
          token: token,
        });
        handlePayment(); // Proceed to payment if user is verified
      } catch (error) {
        alert("You must be logged in to access this page");
        navigate("/login");
        localStorage.removeItem("auth");
      }
    } else {
      alert("You must be logged in to access this page");
      navigate("/login");
    }
  };

  // Razorpay Integration
  const handlePayment = async () => {
    const razorpayKeyId = process.env.REACT_APP_RAZORPAY_KEY_ID;
    const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;

    if (!razorpayKeyId) {
      toast.error("Razorpay Key ID is missing.");
      return;
    }

    setLoading(true);

    try {
      // Create order on backend
      const orderResponse = await axios.post(
        `${BASE_URL}/create-order`,
        {
          amount: subtotal - Discount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const orderData = orderResponse.data;

      const options = {
        key: razorpayKeyId,
        amount: Math.round((subtotal - Discount) * 100),
        currency: "INR",
        name: "Prop The Crop",
        description: "Purchase of Organic Products",
        image: logo,
        order_id: orderData.id, // Add order_id
        handler: async function (response) {
          // Save order details to the backend
          await axios.post(
            `${BASE_URL}/save-order`,
            {
              name: `${userDetails.firstName} ${userDetails.lastName}`,
              email: userDetails.email,
              contact: userDetails.phone,
              totalAmount: subtotal - Discount,
              orderId: orderData.id,
              paymentId: response.razorpay_payment_id,
              products: cartItems.map((item) => ({
                pid: item._id,
                productName: item.title,
                qty: item.qty,
                price: item.price,
              })),
              price: subtotal,
              user_id: user_id,
              address: [
                userDetails.address,
                userDetails.address2,
                userDetails.stateIn,
                userDetails.zip,
                userDetails.country,
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          toast.success("Payment Successful");
          // Clear the cart using Redux action
          dispatch({ type: "CLEAR_CART" });
          setTimeout(() => {
            navigate("/user-dashboard/orders");
          }, 3000);
        },
        prefill: {
          name: `${userDetails.firstName} ${userDetails.lastName}`,
          email: userDetails.email,
          contact: userDetails.phone,
        },
        theme: {
          color: "#A3E635",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to create order. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onerror = () => {
      toast.dismiss();
      toast.error("Failed to load payment gateway. Please try again later.");
    };
    document.body.appendChild(script);
    window.scrollTo(0, 0);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            Complete Your Payment
          </h1>
          <p className="text-gray-600 mb-6">
            You are purchasing organic products from Prop The Crop.
          </p>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
            <h5 className="text-xl font-bold mb-3">Order Summary</h5>
            <ul className="space-y-2">
              <li className="flex justify-between">
                <span>Products ({totalItems})</span>
                <span>₹{Math.round(subtotal)}</span>
              </li>
              <li className="flex justify-between">
                <span>Discount</span>
                <span>₹{Discount}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center px-0 text-red-500 font-semibold">
                Free Shipping
              </li>
              <li className="flex justify-between font-bold">
                <span>Total</span>
                <span>₹{Math.round(subtotal - Discount)}</span>
              </li>
            </ul>
          </div>
          <button
            onClick={handleOnCheckout}
            className="w-full bg-lime-500 hover:bg-lime-600 text-white flex flex-row items-center justify-center font-bold py-3 rounded-lg transition duration-300 ease-in-out"
            disabled={loading}
          >
            {loading ? (
              <>
                <span className="material-symbols-outlined animate-spin">
                  autorenew
                </span>
                Processing...
              </>
            ) : (
              `Pay Now ₹${Math.round(subtotal - Discount)}`
            )}
          </button>
        </div>
      </div>
      <Footer />
      <Toaster />
    </>
  );
};

export default PaymentGateway;
