import React, { useEffect, useState } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CarouselProd = () => {
  const [latestArrivals, setLatestArrivals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showArrows, setShowArrows] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/products`);
        setLatestArrivals(
          response.data.sort(() => 0.5 - Math.random()).slice(0, 5)
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getProducts();
  }, []);

  // Set showArrows based on screen width
  useEffect(() => {
    const handleResize = () => {
      setShowArrows(window.innerWidth >= 1024); // Show arrows only on desktop (>=1024px)
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <i className="fa fa-spinner fa-spin text-4xl text-lime-500"></i>
      </div>
    );
  }

  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      showDots={true}
      arrows={showArrows} // Conditional rendering of navigation buttons
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {latestArrivals.map((item, index) => (
        <div
          key={index}
          className="w-full flex-shrink-0 flex justify-center items-center p-2 md:p-8"
          onClick={() => navigate(`/product/${item.id}`)}
        >
          <div className="flex w-full flex-col-reverse md:flex-row-reverse items-center">
            <div className="w-full md:w-1/2 p-4 flex flex-col justify-center items-start">
              <div className="bg-red-500 text-white px-3 py-1 rounded-lg shadow-lg mb-3 md:mb-4">
                <span className="text-base md:text-lg font-bold">
                  Only at ₹{item.price}
                </span>
              </div>
              <h2 className="text-base md:text-xl font-bold mb-2">
                {item.title}
              </h2>
              <p className="hidden md:block text-sm text-gray-800">
                {item.description.slice(0, 100)}...
              </p>
              <p className="mt-1">
                <span className="text-red-500 text-xs md:text-sm font-bold">
                  *<span className="italic">FREE</span> delivery on all orders
                </span>
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img
                src={item.image[0]}
                loading="lazy"
                className="object-contain max-h-[50vh] max-w-full mx-auto rounded-lg"
                alt={item.title}
                onError={(e) => {
                  e.target.src = "/path/to/placeholder.jpg";
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselProd;
