// src/redux/actions/cartActions.js

// Action to add an item to the cart
export const addItem = (product, qty = 1) => {
  return {
    type: "ADD_ITEM",
    payload: { ...product, qty },
  };
};

// Action to remove an item from the cart
export const removeItem = (product, qty = 1) => {
  return {
    type: "REMOVE_ITEM",
    payload: { ...product, qty },
  };
};

// Action to clear the cart
export const clearCart = () => {
  return {
    type: "CLEAR_CART",
  };
};
