import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../components";

const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center min-h-screen bg-lime-100">
        <div className="text-center">
          <div className="flex items-center justify-center space-x-4">
            <h1 className="text-9xl font-bold text-lime-600 animate-spin-slow">
              4
            </h1>
            <h1 className="text-9xl font-bold text-lime-600 animate-bounce">
              0
            </h1>
            <h1 className="text-9xl font-bold text-lime-600 animate-spin-slow">
              4
            </h1>
          </div>
          <h2 className="text-2xl font-semibold text-lime-700 mt-4">
            Page Not Found
          </h2>
          <Link
            to="/"
            className="mt-6 inline-block px-6 py-3 bg-lime-600 text-white font-semibold rounded-md hover:bg-lime-700"
          >
            <i className="fa fa-arrow-left mr-2"></i> Go Back to Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
