import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const countries = [
  { name: "Afghanistan", code: "93" },
  { name: "Albania", code: "355" },
  { name: "Algeria", code: "213" },
  { name: "Andorra", code: "376" },
  { name: "Angola", code: "244" },
  { name: "Antigua and Barbuda", code: "1-268" },
  { name: "Argentina", code: "54" },
  { name: "Armenia", code: "374" },
  { name: "Australia", code: "61" },
  { name: "Austria", code: "43" },
  { name: "Azerbaijan", code: "994" },
  { name: "Bahamas, The", code: "1-242" },
  { name: "Bahrain", code: "973" },
  { name: "Bangladesh", code: "880" },
  { name: "Barbados", code: "1-246" },
  { name: "Belarus", code: "375" },
  { name: "Belgium", code: "32" },
  { name: "Belize", code: "501" },
  { name: "Benin", code: "229" },
  { name: "Bhutan", code: "975" },
  { name: "Bolivia", code: "591" },
  { name: "Bosnia and Herzegovina", code: "387" },
  { name: "Botswana", code: "267" },
  { name: "Brazil", code: "55" },
  { name: "Brunei", code: "673" },
  { name: "Bulgaria", code: "359" },
  { name: "Burkina Faso", code: "226" },
  { name: "Burundi", code: "257" },
  { name: "Cambodia", code: "855" },
  { name: "Cameroon", code: "237" },
  { name: "Canada", code: "1" },
  { name: "Cape Verde", code: "238" },
  { name: "Central African Republic", code: "236" },
  { name: "Chad", code: "235" },
  { name: "Chile", code: "56" },
  { name: "China, People's Republic of", code: "86" },
  { name: "Colombia", code: "57" },
  { name: "Comoros", code: "269" },
  {
    name: "Congo, Democratic Republic of the (Congo – Kinshasa)",
    code: "243",
  },
  { name: "Congo, Republic of the (Congo – Brazzaville)", code: "242" },
  { name: "Costa Rica", code: "506" },
  { name: "Cote d'Ivoire (Ivory Coast)", code: "225" },
  { name: "Croatia", code: "385" },
  { name: "Cuba", code: "53" },
  { name: "Cyprus", code: "357" },
  { name: "Czech Republic", code: "420" },
  { name: "Denmark", code: "45" },
  { name: "Djibouti", code: "253" },
  { name: "Dominica", code: "1-767" },
  { name: "Dominican Republic", code: "1-809" },
  { name: "Ecuador", code: "593" },
  { name: "Egypt", code: "20" },
  { name: "El Salvador", code: "503" },
  { name: "Equatorial Guinea", code: "240" },
  { name: "Eritrea", code: "291" },
  { name: "Estonia", code: "372" },
  { name: "Ethiopia", code: "251" },
  { name: "Fiji", code: "679" },
  { name: "Finland", code: "358" },
  { name: "France", code: "33" },
  { name: "Gabon", code: "241" },
  { name: "Gambia, The", code: "220" },
  { name: "Georgia", code: "995" },
  { name: "Germany", code: "49" },
  { name: "Ghana", code: "233" },
  { name: "Greece", code: "30" },
  { name: "Grenada", code: "1-473" },
  { name: "Guatemala", code: "502" },
  { name: "Guinea", code: "224" },
  { name: "Guinea-Bissau", code: "245" },
  { name: "Guyana", code: "592" },
  { name: "Haiti", code: "509" },
  { name: "Honduras", code: "504" },
  { name: "Hungary", code: "36" },
  { name: "Iceland", code: "354" },
  { name: "India", code: "91" },
  { name: "Indonesia", code: "62" },
  { name: "Iran", code: "98" },
  { name: "Iraq", code: "964" },
  { name: "Ireland", code: "353" },
  { name: "Israel", code: "972" },
  { name: "Italy", code: "39" },
  { name: "Jamaica", code: "1-876" },
  { name: "Japan", code: "81" },
  { name: "Jordan", code: "962" },
  { name: "Kazakhstan", code: "7" },
  { name: "Kenya", code: "254" },
  { name: "Kiribati", code: "686" },
  {
    name: "Korea, Democratic People's Republic of (North Korea)",
    code: "850",
  },
  { name: "Korea, Republic of (South Korea)", code: "82" },
  { name: "Kuwait", code: "965" },
  { name: "Kyrgyzstan", code: "996" },
  { name: "Laos", code: "856" },
  { name: "Latvia", code: "371" },
  { name: "Lebanon", code: "961" },
  { name: "Lesotho", code: "266" },
  { name: "Liberia", code: "231" },
  { name: "Libya", code: "218" },
  { name: "Liechtenstein", code: "423" },
  { name: "Lithuania", code: "370" },
  { name: "Luxembourg", code: "352" },
  { name: "Macedonia", code: "389" },
  { name: "Madagascar", code: "261" },
  { name: "Malawi", code: "265" },
  { name: "Malaysia", code: "60" },
  { name: "Maldives", code: "960" },
  { name: "Mali", code: "223" },
  { name: "Malta", code: "356" },
  { name: "Marshall Islands", code: "692" },
  { name: "Mauritania", code: "222" },
  { name: "Mauritius", code: "230" },
  { name: "Mexico", code: "52" },
  { name: "Micronesia", code: "691" },
  { name: "Moldova", code: "373" },
  { name: "Monaco", code: "377" },
  { name: "Mongolia", code: "976" },
  { name: "Montenegro", code: "382" },
  { name: "Morocco", code: "212" },
  { name: "Mozambique", code: "258" },
  { name: "Myanmar (Burma)", code: "95" },
  { name: "Namibia", code: "264" },
  { name: "Nauru", code: "674" },
  { name: "Nepal", code: "977" },
  { name: "Netherlands", code: "31" },
  { name: "New Zealand", code: "64" },
  { name: "Nicaragua", code: "505" },
  { name: "Niger", code: "227" },
  { name: "Nigeria", code: "234" },
  { name: "Norway", code: "47" },
  { name: "Oman", code: "968" },
  { name: "Pakistan", code: "92" },
  { name: "Palau", code: "680" },
  { name: "Panama", code: "507" },
  { name: "Papua New Guinea", code: "675" },
  { name: "Paraguay", code: "595" },
  { name: "Peru", code: "51" },
  { name: "Philippines", code: "63" },
  { name: "Poland", code: "48" },
  { name: "Portugal", code: "351" },
  { name: "Qatar", code: "974" },
  { name: "Romania", code: "40" },
  { name: "Russia", code: "7" },
  { name: "Rwanda", code: "250" },
  { name: "Saint Kitts and Nevis", code: "1-869" },
  { name: "Saint Lucia", code: "1-758" },
  { name: "Saint Vincent and the Grenadines", code: "1-784" },
  { name: "Samoa", code: "685" },
  { name: "San Marino", code: "378" },
  { name: "Sao Tome and Principe", code: "239" },
  { name: "Saudi Arabia", code: "966" },
  { name: "Senegal", code: "221" },
  { name: "Serbia", code: "381" },
  { name: "Seychelles", code: "248" },
  { name: "Sierra Leone", code: "232" },
  { name: "Singapore", code: "65" },
  { name: "Slovakia", code: "421" },
  { name: "Slovenia", code: "386" },
  { name: "Solomon Islands", code: "677" },
  { name: "Somalia", code: "252" },
  { name: "South Africa", code: "27" },
  { name: "Spain", code: "34" },
  { name: "Sri Lanka", code: "94" },
  { name: "Sudan", code: "249" },
  { name: "Suriname", code: "597" },
  { name: "Swaziland", code: "268" },
  { name: "Sweden", code: "46" },
  { name: "Switzerland", code: "41" },
  { name: "Syria", code: "963" },
  { name: "Tajikistan", code: "992" },
  { name: "Tanzania", code: "255" },
  { name: "Thailand", code: "66" },
  { name: "Timor-Leste (East Timor)", code: "670" },
  { name: "Togo", code: "228" },
  { name: "Tonga", code: "676" },
  { name: "Trinidad and Tobago", code: "1-868" },
  { name: "Tunisia", code: "216" },
  { name: "Turkey", code: "90" },
  { name: "Turkmenistan", code: "993" },
  { name: "Tuvalu", code: "688" },
  { name: "Uganda", code: "256" },
  { name: "Ukraine", code: "380" },
  { name: "United Arab Emirates", code: "971" },
  { name: "United Kingdom", code: "44" },
  { name: "United States", code: "1" },
  { name: "Uruguay", code: "598" },
  { name: "Uzbekistan", code: "998" },
  { name: "Vanuatu", code: "678" },
  { name: "Vatican City", code: "379" },
  { name: "Venezuela", code: "58" },
  { name: "Vietnam", code: "84" },
  { name: "Yemen", code: "967" },
  { name: "Zambia", code: "260" },
  { name: "Zimbabwe", code: "263" },
];

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("India"); // Default to India
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [emailOtpSent, setEmailOtpSent] = useState(false);
  // const [messages, setMessages] = useState(new Set());
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSendOtp = async () => {
    try {
      const selectedCountryObj = countries.find((c) => c.name === countryCode);
      const selectedCountry = selectedCountryObj
        ? selectedCountryObj.code
        : null;
      if (!selectedCountry) {
        toast.error("Invalid country code");
        return;
      }
      if (!selectedCountry) {
        toast.error("Invalid country code");
        return;
      }
      const response = await axios.post(`${BASE_URL}/send-otp`, {
        phoneNumber: `+${selectedCountry}${phoneNumber}`,
        email: email,
        use: "register",
      });
      if (
        name.length === 0 ||
        email.length === 0 ||
        password.length === 0 ||
        phoneNumber.length === 0
      ) {
        toast.dismiss();
        toast.error("All fields are required");
        return;
      }
      if (phoneNumber.length !== 10 && countryCode === "India") {
        toast.dismiss();
        toast.error("Phone number should be 10 digits");
        return;
      }
      if (response.status === 200) {
        toast.success("SMS OTP sent successfully");
        setOtpSent(true);
      } else if (response.status === 409) {
        toast.error(response.data.error);
      } else {
        toast.error("Failed to send SMS OTP");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(`${error.response?.data?.error || error.message}`);
    }
  };

  const handleSendEmailOtp = async () => {
    if (
      name.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      phoneNumber.length === 0
    ) {
      toast.dismiss();
      toast.error("All fields are required");
      return;
    }
    if (phoneNumber.length !== 10 && countryCode === "India") {
      toast.dismiss();
      toast.error("Phone number should be 10 digits");
      return;
    }
    try {
      const selectedCountry = countries.find(
        (c) => c.name === countryCode
      ).code;
      const response = await axios.post(`${BASE_URL}/send-otp-email`, {
        email: email,
        phoneNumber: `+${selectedCountry}${phoneNumber}`,
        user_name: name,
        use: "register",
      });

      if (response.status === 200) {
        toast.success("Email OTP sent successfully");
        setEmailOtpSent(true);
      } else if (response.status === 409) {
        toast.error(response.data.error);
      } else {
        toast.error("Failed to send Email OTP");
      }
    } catch (error) {
      toast.error(`${error.response?.data?.error || error.message}`);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const selectedCountry = countries.find(
        (c) => c.name === countryCode
      ).code;
      const response = await axios.post(`${BASE_URL}/verify-otp`, {
        phoneNumber: `+${selectedCountry}${phoneNumber}`,
        otp,
      });
      if (response.status === 200) {
        toast.success("OTP verified successfully");
        handleRegister();
      } else {
        toast.error("Invalid OTP");
      }
    } catch (error) {
      toast.error("Failed to verify SMS OTP");
    }
  };

  const handleVerifyEmailOtp = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/verify-otp-email`, {
        email,
        otp: emailOtp,
      });
      if (response.status === 200) {
        toast.success("Email OTP verified successfully");
        if (countries.find((c) => c.name === countryCode).code === "91") {
          if (otp.length === 0) {
            toast.error("SMS OTP is required");
            return;
          }
          handleVerifyOtp();
        } else {
          handleRegister();
        }
      } else {
        toast.error("Invalid Email OTP");
      }
    } catch (error) {
      toast.error("Failed to verify Email OTP");
    }
  };

  const handleRegister = async () => {
    try {
      const selectedCountry = countries.find(
        (c) => c.name === countryCode
      ).code;
      const response = await axios.post(`${BASE_URL}/register`, {
        name,
        phoneNumber: `+${selectedCountry}${phoneNumber}`,
        email,
        password,
      });

      if (response.status === 201) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else if (response.status === 409) {
        toast.error(response.data.error);
      } else {
        toast.error("Failed to register");
      }
    } catch (error) {
      toast.error("Failed to register");
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    // Validation checks
    if (
      name.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      phoneNumber.length === 0
    ) {
      toast.dismiss();
      toast.error("All fields are required");
      return;
    }

    if (phoneNumber.length !== 10 && countryCode === "India") {
      toast.dismiss();
      toast.error("Phone number should be 10 digits");
      return;
    }

    if (emailOtpSent) {
      handleVerifyEmailOtp();
    } else {
      handleSendEmailOtp();
      if (countries.find((c) => c.name === countryCode).code === "91") {
        handleSendOtp();
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center bg-gray-100 px-4">
        <div className="bg-white shadow-lg rounded-lg my-[30%] md:my-[3%] p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">
            Register
          </h1>
          <form onSubmit={handleOnSubmit} className="space-y-4 sm:space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-md sm:text-lg text-gray-600"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={20}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-md sm:text-lg text-gray-600"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            <div className="">
              <label
                htmlFor="password"
                className="block text-md sm:text-lg text-gray-600"
              >
                Password
              </label>
              <div className="flex">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
                />
                <button
                  className="-ml-10 sm:-ml-12 flex items-center justify-center  rounded-r-lg p-2"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <span className="material-symbols-outlined">
                    {showPassword ? "visibility_off" : "visibility"}
                  </span>
                </button>
              </div>
            </div>
            <div>
              <label
                htmlFor="countryCode"
                className="block text-md sm:text-lg text-gray-600"
              >
                Country Code
              </label>
              <select
                id="countryCode"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name} ({`+${country.code}`})
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-md sm:text-lg text-gray-600"
              >
                Phone Number
              </label>
              <input
                type="number"
                id="phoneNumber"
                placeholder="Enter your phone number"
                value={phoneNumber}
                maxLength={10}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
              />
            </div>
            {otpSent &&
              countries.find((c) => c.name === countryCode).code === "91" && (
                <div>
                  <label
                    htmlFor="otp"
                    className="block text-md sm:text-lg text-gray-600"
                  >
                    SMS OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    placeholder="Enter SMS OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
                  />
                </div>
              )}
            {emailOtpSent && (
              <div>
                <label
                  htmlFor="emailOtp"
                  className="block text-md sm:text-lg text-gray-600"
                >
                  Email OTP
                </label>
                <input
                  type="text"
                  id="emailOtp"
                  placeholder="Enter Email OTP"
                  value={emailOtp}
                  onChange={(e) => setEmailOtp(e.target.value)}
                  required
                  className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
                />
              </div>
            )}
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-lime-500 rounded-lg hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-lime-400"
            >
              {emailOtpSent ? "Verify OTP" : "Send OTP"}
            </button>
          </form>
          <p className="mt-4 text-center">
            Already have an account?{" "}
            <Link to="/login" className="text-lime-500">
              Login here
            </Link>
          </p>
          <Toaster toastOptions={{ maxToasts: 1 }} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
