import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import "./components/css/index.css";

import {
  Home,
  Product,
  Products,
  AboutPage,
  ContactPage,
  Cart,
  Login,
  Register,
  Checkout,
  PageNotFound,
} from "./pages";
import Dashboard from "./pages/userDashboard/Dashboard";
import UserOrders from "./pages/userDashboard/UserOrders";
import UserAddress from "./pages/userDashboard/UserAddress";
import UserInvoices from "./pages/userDashboard/UserInvoices";
import ForgotPassword from "./pages/ForgotPassword";
import UserCredentials from "./pages/userDashboard/UserCredentials";
import PaymentGateway from "./pages/PaymentGateway";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ReturnPolicy from "./components/ReturnPolicy";
import TermsAndConditions from "./components/TermsAndConditions";

// on page refresh it shall navigate to top of the page
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

window.onload = function () {
  window.scrollTo(0, 0);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/product/*" element={<PageNotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/payment-gateway" element={<PaymentGateway />} />
          <Route path="/user-dashboard" element={<Dashboard />} />
          <Route path="/user-dashboard/orders" element={<UserOrders />} />
          <Route path="/user-dashboard/address" element={<UserAddress />} />
          <Route path="/user-dashboard/invoices" element={<UserInvoices />} />
          <Route
            path="/user-dashboard/credentials"
            element={<UserCredentials />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
