import React, { useEffect } from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addItem, removeItem, clearCart } from "../redux/actions/cartActions";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Cart = () => {
  const cartItems = useSelector((state) => state.cart) || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("auth"))?.token;

  const handleOnCheckout = async () => {
    if (token) {
      try {
        await axios.post(`${BASE_URL}/verifyUser`, { token });
        navigate("/checkout");
      } catch (error) {
        toast.error("You must be logged in to access this page");
        navigate("/login");
        localStorage.removeItem("auth");
      }
    } else {
      toast.error("You must be logged in to access this page");
      navigate("/login");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const EmptyCart = () => (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-5 bg-light text-center">
          <h4 className="p-3 display-5">Your Cart is Empty</h4>
          <Link
            to="/"
            className="btn flex items-center justify-center w-fit mx-auto bg-lime-500 text-white px-4 py-2 rounded-md transition hover:bg-lime-600"
          >
            <span className="material-symbols-outlined mr-2">arrow_back</span>
            Continue Shopping
          </Link>
        </div>
      </div>
    </div>
  );

  const handleAddItem = (product) => {
    dispatch(addItem(product, 1));
    toast.dismiss(); // Dismiss any active toast
    toast.success("Increased product quantity");
  };

  const handleRemoveItem = (product) => {
    dispatch(removeItem(product, 1));
    toast.dismiss(); // Dismiss any active toast
    toast.success("Decreased product quantity");
  };

  const handleClearCart = () => {
    dispatch(clearCart());
    toast.dismiss(); // Dismiss any active toast
    toast.success("Cart cleared");
  };

  const ShowCart = () => {
    let subtotal = 0;
    let discount = 0;
    let totalItems = 0;
    cartItems.forEach((item) => {
      subtotal += item.price * item.qty;
    });

    cartItems.forEach((item) => {
      totalItems += item.qty;
    });

    if (subtotal > 2500) {
      discount = 300;
    } else if (subtotal > 1500) {
      discount = 200;
    } else if (subtotal > 1000) {
      discount = 150;
    } else if (subtotal > 500) {
      discount = 50;
    } else {
      discount = 0;
    }

    const totalAmount = subtotal - discount;

    return (
      <section className="h-100 gradient-custom">
        <div className="container py-5">
          <div className="row d-flex justify-content-center my-4">
            <div className="col-md-8">
              <div className="card mb-4 shadow-sm rounded-lg">
                <div className="card-header py-3 bg-gray-100 flex justify-between items-center">
                  <h5 className="mb-0 text-xl font-bold">Item List</h5>
                  <button
                    className="btn flex items-center justify-center bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                    onClick={handleClearCart}
                  >
                    <span className="material-symbols-outlined">delete</span>
                    Clear Cart
                  </button>
                </div>
                <div className="card-body">
                  {cartItems.map((item) => (
                    <div key={item.id}>
                      <div className="row d-flex align-items-center">
                        <div className="col-lg-3 col-md-12">
                          <img
                            src={
                              Array.isArray(item.image)
                                ? item.image[0]
                                : item.image
                            }
                            alt={item.title}
                            className="rounded shadow-sm w-100"
                            style={{ maxWidth: "100px", height: "75px" }}
                          />
                        </div>
                        <div className="col-lg-5 col-md-6">
                          <p className="font-semibold">{item.title}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 flex items-center justify-between">
                          <button
                            className="btn px-3 bg-gray-200 hover:bg-gray-300 transition rounded-md"
                            onClick={() => handleRemoveItem(item)}
                          >
                            <span className="material-symbols-outlined">
                              remove
                            </span>
                          </button>
                          <p className="mx-4">{item.qty}</p>
                          <button
                            className="btn px-3 bg-lime-500 text-white rounded-md hover:bg-lime-600 transition"
                            onClick={() => handleAddItem(item)}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                          </button>
                          <p className="text-lg font-semibold ml-4">
                            ₹{item.price * item.qty}
                          </p>
                        </div>
                      </div>
                      <hr className="my-4" />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card mb-4 shadow-sm rounded-lg">
                <div className="card-header py-3 bg-gray-100">
                  <h5 className="mb-0 text-xl font-bold">Order Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products ({totalItems})<span>₹{subtotal.toFixed(2)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Discount
                      <span>₹{discount.toFixed(2)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 text-red-500 font-semibold">
                      Free Shipping
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <strong>Total amount</strong>
                      <strong>₹{totalAmount.toFixed(2)}</strong>
                    </li>
                  </ul>
                  <button
                    onClick={handleOnCheckout}
                    className="btn bg-lime-500 text-white w-full py-3 rounded-md transition hover:bg-lime-600"
                  >
                    Go to Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center text-3xl font-bold mb-4">Cart</h1>
        <hr />
        {cartItems.length > 0 ? <ShowCart /> : <EmptyCart />}
      </div>
      <Footer />
      <Toaster toastOptions={{ maxToasts: 1 }} />
    </>
  );
};

export default Cart;
