import React from "react";
import { Navbar, Product, Footer } from "../components";
import CarouselProd from "../components/CarouselProd";

function Home() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <CarouselProd />
      <Product />
      <Footer />
    </>
  );
}

export default Home;
