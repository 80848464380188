import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer, Navbar } from "../components";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [waitTime, setWaitTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const endTime = localStorage.getItem("otpEndTime");
    if (endTime) {
      const remainingTime = Math.max(
        0,
        Math.floor((new Date(endTime) - new Date()) / 1000) // Time in seconds
      );
      setWaitTime(remainingTime);
      if (remainingTime > 0) {
        startTimer(remainingTime);
      }
    }
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { email, user_name: "User", use: "forgotPW" };
      await axios.post(`${BASE_URL}/send-otp-email`, payload);
      toast.dismiss();
      toast.success("Email OTP sent successfully");
      const endTime = new Date(new Date().getTime() + 5 * 60000); // 5 minutes from now
      localStorage.setItem("otpEndTime", endTime);
      setWaitTime(5 * 60); // Set initial wait time to 5 minutes in seconds
      startTimer(5 * 60); // Start countdown in seconds
    } catch (error) {
      toast.error(
        `Failed to send OTP: ${error.response?.data?.error || error.message}`
      );
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { email, otp };
      await axios.post(`${BASE_URL}/verify-otp-email`, payload);
      toast.dismiss();
      toast.success("Email OTP verified successfully");
      setIsOtpVerified(true);
    } catch (error) {
      toast.dismiss();
      toast.error(
        `Failed to verify OTP: ${error.response?.data?.error || error.message}`
      );
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const payload = { email, newPassword, otp };
      const response = await axios.post(
        `${BASE_URL}/reset-password-email`,
        payload
      );
      toast.dismiss();
      toast.success(response.data.message);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred");
    }
  };

  const startTimer = (initialWaitTime) => {
    if (timer) {
      clearInterval(timer);
    }
    let currentWaitTime = initialWaitTime;
    const newTimer = setInterval(() => {
      currentWaitTime -= 1;
      setWaitTime(currentWaitTime);
      if (currentWaitTime <= 0) {
        clearInterval(newTimer);
        localStorage.removeItem("otpEndTime");
      }
    }, 1000); // 1 second interval for real-time countdown
    setTimer(newTimer);
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center bg-gray-100 px-4">
        <div className="bg-white shadow-lg rounded-lg my-[30%] md:my-[5%] p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-6">
            Forgot Password
          </h1>
          {!isOtpVerified ? (
            <>
              <form
                onSubmit={handleOnSubmit}
                className="space-y-4 sm:space-y-6"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-md sm:text-lg text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="example@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
                    required
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="w-full py-2 sm:py-3 px-4 bg-lime-500 text-white font-bold rounded-lg hover:bg-lime-600 transition duration-200"
                    disabled={waitTime > 0}
                  >
                    {waitTime > 0 ? `Wait ${formatTime(waitTime)}` : "Send OTP"}
                  </button>
                </div>
              </form>
              {waitTime > 0 && (
                <form
                  onSubmit={handleOtpSubmit}
                  className="space-y-4 sm:space-y-6 mt-4"
                >
                  <div>
                    <label
                      htmlFor="otp"
                      className="block text-md sm:text-lg text-gray-600"
                    >
                      Enter OTP
                    </label>
                    <input
                      type="text"
                      id="otp"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
                      required
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="w-full py-2 sm:py-3 px-4 bg-lime-500 text-white font-bold rounded-lg hover:bg-lime-600 transition duration-200"
                    >
                      Verify OTP
                    </button>
                  </div>
                </form>
              )}
              {waitTime > 0 && (
                <div className="text-center mt-4 text-red-500">
                  Please wait {formatTime(waitTime)} before requesting another
                  OTP.
                </div>
              )}
            </>
          ) : (
            <form
              onSubmit={handleResetPassword}
              className="space-y-4 sm:space-y-6"
            >
              <div>
                <label className="block text-md sm:text-lg text-gray-600">
                  New Password:
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  className="w-full px-3 py-2 sm:px-4 sm:py-3 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:border-transparent"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="w-full py-2 sm:py-3 px-4 bg-lime-500 text-white font-bold rounded-lg hover:bg-lime-600 transition duration-200"
                >
                  Reset Password
                </button>
              </div>
            </form>
          )}
          <div className="text-center mt-4">
            <Link to="/login" className="text-lime-500 underline">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
      <Footer />
      <Toaster />
    </>
  );
};

export default ForgotPassword;
