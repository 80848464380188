// src/redux/reducers/cartReducer.js

const initialCart = JSON.parse(localStorage.getItem("cart")) || [];

const cartReducer = (state = initialCart, action) => {
  let newState;
  switch (action.type) {
    case "ADD_ITEM":
      const productToAdd = action.payload;
      const existingProduct = state.find((item) => item.id === productToAdd.id);

      if (existingProduct) {
        // Update the quantity by adding the new quantity
        newState = state.map((item) =>
          item.id === productToAdd.id
            ? { ...item, qty: item.qty + productToAdd.qty }
            : item
        );
      } else {
        // Add the new product with the specified quantity
        newState = [...state, { ...productToAdd, qty: productToAdd.qty }];
      }
      break;

    case "REMOVE_ITEM":
      const productToRemove = action.payload;
      const existingProductToRemove = state.find(
        (item) => item.id === productToRemove.id
      );

      if (existingProductToRemove.qty === 1) {
        // Remove the product from the cart
        newState = state.filter((item) => item.id !== productToRemove.id);
      } else {
        // Decrement the quantity by the specified amount
        newState = state.map((item) =>
          item.id === productToRemove.id
            ? { ...item, qty: item.qty - productToRemove.qty }
            : item
        );
      }
      break;

    case "CLEAR_CART":
      // Clear the entire cart
      newState = [];
      break;

    default:
      newState = state;
  }

  // Update local storage
  localStorage.setItem("cart", JSON.stringify(newState));
  return newState;
};

export default cartReducer;
