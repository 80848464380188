import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { Footer, Navbar } from "../../components";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Add a request interceptor to include the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const token = auth?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Initial state for the reducer
const initialState = {
  invoices: [],
  loading: true,
  error: null,
};

// Reducer function to manage state
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INVOICES":
      return {
        ...state,
        invoices: action.payload,
        error: null,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const UserInvoices = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  // Check user authentication on component mount
  useEffect(() => {
    const checkAuth = async () => {
      const token = JSON.parse(localStorage.getItem("auth"))?.token;
      if (token) {
        try {
          await axios.post(`${BASE_URL}/verifyUser`, { token });
        } catch (error) {
          toast.error("Authentication failed. Please log in again.");
          navigate("/login");
          localStorage.removeItem("auth");
        }
      } else {
        toast.error("You must be logged in to access this page.");
        navigate("/login");
      }
    };

    checkAuth();
  }, [navigate]);

  // Fetch invoices on component mount
  useEffect(() => {
    const fetchInvoices = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await axiosInstance.get("/userInvoices");
        dispatch({ type: "SET_INVOICES", payload: response.data.invoices });
      } catch (error) {
        console.error("Error fetching invoices:", error);
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to load invoices. Please try again later.",
        });
        toast.error("Failed to load invoices");
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };

    fetchInvoices();
    window.scrollTo(0, 0);
  }, []);

  // Updated handlePrintInvoice to download the PDF
  const handlePrintInvoice = async (orderId) => {
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}/generateBill`,
        { orderId },
        {
          responseType: "blob", // Important to handle binary data
        }
      );

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a link element
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", `invoice_${orderId}.pdf`);

      // Append to the document and trigger click
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Invoice downloaded successfully");
    } catch (error) {
      console.error("Error generating invoice:", error);
      toast.error("Failed to generate invoice");
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Toaster position="top-right" />
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          {state.loading ? (
            <div className="flex justify-center items-center py-20">
              <div className="w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
            </div>
          ) : state.error ? (
            <div className="text-center text-red-500">
              <p>{state.error}</p>
            </div>
          ) : (
            <InvoicesView
              invoices={state.invoices}
              handlePrintInvoice={handlePrintInvoice}
            />
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

// InvoicesView Component
const InvoicesView = ({ invoices, handlePrintInvoice }) => (
  <div className="space-y-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">User Invoices</h2>
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Order ID
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Product Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Quantity
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Price
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Object.keys(invoices).length > 0 ? (
            Object.keys(invoices).map((orderId) => (
              <tr key={orderId} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {orderId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {invoices[orderId][0].map((product) => (
                    <div key={product.pid}>{product.productName}</div>
                  ))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {invoices[orderId][0].map((product) => (
                    <div key={product.pid}>{product.qty}</div>
                  ))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {invoices[orderId][0].map((product) => (
                    <div key={product.pid}>
                      ₹{(product.price * product.qty).toFixed(2)}
                    </div>
                  ))}
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => handlePrintInvoice(orderId)}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-lime-500 hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                  >
                    <span className="material-symbols-outlined mr-2">
                      download
                    </span>
                    Download Invoice
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="5"
                className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500"
              >
                No invoices found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

export default UserInvoices;
