import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Footer, Navbar } from "../../components";

const UserAddress = () => {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("auth"))?.token;
  const states_of_india = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  useEffect(() => {
    const handleOnCheckout = async () => {
      if (token) {
        try {
          const res = await axios.post(`${BASE_URL}/verifyUser`, {
            token: token,
          });
          // User is verified, proceed with loading the page
        } catch (error) {
          alert("You must be logged in to access this page");
          navigate("/login");
          localStorage.removeItem("auth");
        }
      } else {
        alert("You must be logged in to access this page");
        navigate("/login");
      }
    };

    handleOnCheckout();

    const savedData = JSON.parse(localStorage.getItem("billingAddress"));
    if (savedData) {
      document.getElementById("firstName").value = savedData.firstName;
      document.getElementById("lastName").value = savedData.lastName;
      document.getElementById("email").value = savedData.email;
      document.getElementById("address").value = savedData.address;
      document.getElementById("address2").value = savedData.address2 || "";
      document.getElementById("country").value = savedData.country;
      document.getElementById("stateIn").value = savedData.stateIn;
      document.getElementById("zip").value = savedData.zip;
      document.getElementById("phone").value = savedData.phone;
    }
    window.scrollTo(0, 0);
  }, [navigate, token, BASE_URL]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      address: e.target.address.value,
      address2: e.target.address2.value,
      country: e.target.country.value,
      stateIn: e.target.stateIn.value,
      zip: e.target.zip.value,
      phone: e.target.phone.value,
    };

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.address ||
      !formData.country ||
      !formData.stateIn ||
      !formData.zip ||
      !formData.phone
    ) {
      toast.error("Please fill all the required fields.");
      return;
    }

    localStorage.setItem("billingAddress", JSON.stringify(formData));
    toast.success("Address saved successfully");
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto py-5">
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <h4 className="text-xl font-bold mb-3">Billing Address</h4>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
            </div>

            <div className="mt-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                placeholder="you@example.com"
                required
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700"
              >
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                placeholder="1234 Main St"
                required
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="address2"
                className="block text-sm font-medium text-gray-700"
              >
                Address 2 <span className="text-gray-400">(Optional)</span>
              </label>
              <input
                type="text"
                id="address2"
                name="address2"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                placeholder="Apartment or suite"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                >
                  <option>India</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="stateIn"
                  className="block text-sm font-medium text-gray-700"
                >
                  State
                </label>
                <select
                  id="stateIn"
                  name="stateIn"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                >
                  {states_of_india.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  htmlFor="zip"
                  className="block text-sm font-medium text-gray-700"
                >
                  Zip
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
            </div>

            <div className="mt-4">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  +91
                </span>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="mt-1 p-2 border border-gray-300 rounded-r-md w-full"
                  placeholder="1234567890"
                  required
                />
              </div>
            </div>

            <div className="flex justify-between items-center mt-6">
              <button
                type="submit"
                className="bg-lime-500 text-white px-4 py-2 rounded-md transition flex flex-row items-center justify-center hover:bg-lime-600"
              >
                Save Address{" "}
                <span className="material-symbols-outlined">check</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Toaster />
      <Footer />
    </>
  );
};

export default UserAddress;
