import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Footer } from "../../components";
import toast, { Toaster } from "react-hot-toast";

const UserCredentials = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [strength, setStrength] = useState(0);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("auth"))?.token;

  useEffect(() => {
    if (!token) {
      toast.error("You must be logged in to access this page");
      navigate("/login");
    }
  }, [token, navigate]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;

    if (!token) {
      toast.error("User is not authenticated");
      setLoading(false);
      return;
    }

    if (currentPassword === newPassword) {
      toast.error("New password cannot be the same as the current password");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/update-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ user_id, currentPassword, newPassword }),
      });

      if (response.ok) {
        toast.success("Password updated successfully");
        setTimeout(() => {
          navigate("/user-dashboard");
        }, 3000);
      } else if (response.status === 401) {
        toast.error("Incorrect current password");
      } else {
        toast.error("Failed to update password");
      }
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const checkStrength = (password) => {
    let score = 0;
    if (password.length >= 8) score++;
    if (/[A-Z]/.test(password)) score++;
    if (/[0-9]/.test(password)) score++;
    if (/[^A-Za-z0-9]/.test(password)) score++;
    setStrength(score);
  };

  const getStrengthLabel = () => {
    switch (strength) {
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "";
    }
  };

  return (
    <>
      <Navbar />
      <Toaster />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-3xl font-semibold text-center text-lime-600 mb-6">
            Update Password
          </h2>
          <form onSubmit={handlePasswordChange} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Current Password:
              </label>
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <span className="material-symbols-outlined">
                    {passwordVisible ? "visibility_off" : "visibility"}
                  </span>
                </span>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                New Password:
              </label>
              <div className="relative">
                <input
                  type={newPasswordVisible ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    checkStrength(e.target.value);
                  }}
                  required
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={toggleNewPasswordVisibility}
                >
                  <span className="material-symbols-outlined">
                    {newPasswordVisible ? "visibility_off" : "visibility"}
                  </span>
                </span>
              </div>
              <div className="mt-2 text-sm">
                <span
                  className={`font-medium ${
                    strength < 3 ? "text-red-500" : "text-lime-600"
                  }`}
                >
                  {getStrengthLabel()}
                </span>
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 disabled:bg-gray-400"
            >
              {loading ? "Updating..." : "Update Password"}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserCredentials;
