import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-10">
      <div className="container mx-auto px-6 lg:px-8">
        {/* Footer Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {/* Company Info */}
          <div>
            <h5 className="text-lime-500 font-semibold text-lg uppercase mb-4">
              Prop The Crop
            </h5>
            <p className="text-gray-400">
              Your trusted source for organic and sustainable products. Join us
              in promoting a healthier lifestyle through wholesome and natural
              choices.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h5 className="text-lime-500 font-semibold text-lg uppercase mb-4">
              Quick Links
            </h5>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/about"
                  className="hover:text-lime-500 transition-colors"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-lime-500 transition-colors"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-and-conditions"
                  className="hover:text-lime-500 transition-colors"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="hover:text-lime-500 transition-colors"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h5 className="text-lime-500 font-semibold text-lg uppercase mb-4">
              Follow Us
            </h5>
            <div className="flex space-x-4">
              <a
                href="https://www.youtube.com/@ProptheCrop"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-lime-500 transition-colors text-2xl"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                href="https://instagram.com/prop_the_crop"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-lime-500 transition-colors text-2xl"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://twitter.com/prop_the_crop"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-lime-500 transition-colors text-2xl"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>

          {/* Contact Information */}
          <div>
            <h5 className="text-lime-500 font-semibold text-lg uppercase mb-4">
              Contact Us
            </h5>
            <p className="text-gray-400 mb-2">
              <i className="fa fa-envelope mr-2"></i>
              <a
                href="mailto:operations@propthecrop.com"
                className="hover:text-lime-500"
              >
                operations@propthecrop.com
              </a>
            </p>
            <p className="text-gray-400 mb-2">
              <i className="fab fa-whatsapp mr-2"></i>
              <a href="tel:+919490029227" className="hover:text-lime-500">
                +91 9490029227
              </a>
            </p>
            <p className="text-gray-400">GSTIN: 37ABACA9299F1ZA</p>
            <p className="text-gray-400">FSSAI: 20124182000313</p>
          </div>
        </div>

        {/* Divider */}
        <div className="my-8 border-t border-gray-700"></div>
        {/* Company Address */}
        <div className="mb-8">
          <h5 className="text-lg font-bold text-lime-500 uppercase mb-4">
            Company Address
          </h5>
          <p className="text-gray-400 leading-relaxed">
            <i className="fa fa-map-marker mr-2 text-lime-400"></i>
            Aadra Organics Private Limited - C/O RAJESH KANUMURU, Kalaya
            Kagollu, Kagulapadu, Nellore, Buchireddypalem, Andhra Pradesh,
            India, 524305
          </p>
        </div>

        {/* Divider */}
        <div className="my-8 border-t border-gray-700"></div>

        {/* Footer Bottom */}
        <div className="flex flex-col lg:flex-row items-center justify-between text-sm text-gray-500">
          <p>
            © {new Date().getFullYear()} Prop The Crop. All rights reserved.
          </p>
          <p>Designed by Prop The Crop Team.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
